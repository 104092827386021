import { Component, Renderer2, OnInit } from '@angular/core';

@Component({
  selector: 'header-govbr',
  templateUrl: './header-gov-br.component.html',
  styleUrls: ['./header-gov-br.component.scss']
})
export class HeaderGovBrComponent implements OnInit {
  private isContrastEnabled = false;
  private fontSize = 100; // Tamanho inicial da fonte em %

  constructor(
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    // Verifica se o contraste estava ativado antes da atualização da página
    this.checkAndApplyContrast();
  }

  toggleContrast(): void {
    const body = document.body; // Seleciona o elemento body

    if (this.isContrastEnabled) {
      this.renderer.removeAttribute(body, 'id'); // Remove o ID do body
    } else {
      this.renderer.setAttribute(body, 'id', 'contrast'); // Adiciona o ID contrast ao body
    }

    this.isContrastEnabled = !this.isContrastEnabled; // Atualiza o estado

    // Salva o estado do contraste no localStorage
    localStorage.setItem('contrastEnabled', this.isContrastEnabled.toString());
  }

  increaseFontSize(): void {
    if (this.fontSize < 180) { // Limite máximo de 180%
      this.fontSize += 10;
      this.applyFontSizeToBody();
    }
  }

  decreaseFontSize(): void {
    if (this.fontSize > 100) { // Limite mínimo de 100%
      this.fontSize -= 10;
      this.applyFontSizeToBody();
    }
  }

  private applyFontSizeToBody(): void {
    const body = document.body;
    this.renderer.setStyle(body, 'zoom', `${this.fontSize}%`);
  }

  // Método para verificar e reaplicar o contraste
  private checkAndApplyContrast(): void {
    const contrastEnabled = localStorage.getItem('contrastEnabled') === 'true';
    if (contrastEnabled) {
      const body = document.body;
      this.renderer.setAttribute(body, 'id', 'contrast'); // Aplica o contraste
      this.isContrastEnabled = true; // Atualiza o estado interno
    }
  }

  // Método para ser chamado após o modal ser fechado ou o body ser modificado
  public reapplyContrastIfNeeded(): void {
    this.checkAndApplyContrast();
  }
}
