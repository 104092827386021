<header-govbr></header-govbr>

  <!-- LOGIN -->
  <section class="login_plataforma_conviva">
    <div class="container">
      <div class="form-login"  data-aos="zoom-in">
        <a href="javascript:void(0)" class="logo-conviva">
          <img src="../../../assets/img/logo-conviva-sp.svg" alt="" />
        </a>
        <a href="javascript:void(0)" class="btn_gov_br" (click)="loginGovBr()">
          <img src="../../../assets/img/icons/icon-entrar.svg" alt="">
          Entrar com Gov.br
        </a>
        <a href="https://www.saopaulo.sp.gov.br/" target="_blank" class="logo-gov-sp">
          <img src="../../../assets/img/logo-sp-gov-br.svg" alt="" />
        </a>
      </div>
    </div>
  </section>
