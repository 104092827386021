//Principais
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgSelectConfig } from "@ng-select/ng-select";
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from "rxjs";

//Models
import { TurmaEnvolvido } from "src/app/shared/models/envolvido/TurmaEnvolvido";

//Services
import { BaseComponent } from 'components/base/base.component';
import { BuscarEnvolvidos } from 'src/app/shared/models/envolvido/BuscarEnvolvidos';
import { FiltroPadrao } from 'src/app/shared/models/filtro-padrao/filtroPadrao';
import { ConvivaErrorHandlingService } from 'src/app/shared/services/convivaErrorHandlingService';
import { EnvolvidoService } from "src/app/shared/services/envolvido.service";

@Component({
  selector: 'app-busca',
  templateUrl: './busca.component.html',
  styleUrls: ['./busca.component.scss']
})

export class BuscaComponent extends BaseComponent implements OnInit {


  constructor(
    private config: NgSelectConfig,
    private envolvidoService: EnvolvidoService,
    private toastService: ToastrService,
    private ConvivaErrors: ConvivaErrorHandlingService
  ) {
    super()
  }

  // public diretorias: Diretoria[] = [];
  // public escolas: Escola[] = [];
  public ra?: string  ;
  public nome?: string ;
  public turmasEnvolvidos: TurmaEnvolvido[] = [];
  public resultPesquisa: boolean = false;
  public codigoTurmaAtual: number = 0;
  public submitted = false;
  public formBuscar!: FormGroup;
  public get form() { return this.formBuscar.controls; }
  public get codigoDiretoria() { return this.formBuscar.get('codigoDiretoria')! }
  public get codigoEscola() { return this.formBuscar.get('codigoEscola')! }
  public get codigoTurno() { return this.formBuscar.get('codigoTurno')! }
  public get codigoTurma() { return this.formBuscar.get('codigoTurma')! }

  public async ngOnInit() {
    this.formBuscar = new FormGroup({
      codigoDiretoria: new FormControl(null, [Validators.required]),
      codigoEscola: new FormControl(null, [Validators.required]),
      codigoTurno: new FormControl(null),
      codigoTurma: new FormControl(null),
      nomeAluno: new FormControl(null),
    });

  }




  public async OnPesquisar(filtros?: FiltroPadrao) {
    this.spinnerService.show();
    this.filtros = filtros!;
    this.resultPesquisa = true;

    const nomeRa = this.form.nomeAluno.value;


    if ((!nomeRa || nomeRa == "") && !filtros?.codigoDiretoria && !filtros?.codigoEscola) {
      this.toastr.error("Adicione ao menos um filtro");
      this.spinnerService.hide();
      return;
    }

    if (this.ValidaRaNome(nomeRa)) {
      this.spinnerService.hide();
      return;
    }


    var envolvidoBuscar = this.setObjetoEnvolvidos();
    const result = await lastValueFrom(this.envolvidoService.GetEnvolvidosPorTurma(envolvidoBuscar))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });

    if (!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.turmasEnvolvidos = result.data;
    if (result.data != null && this.turmasEnvolvidos.length == 0 && result.data.lenght == 0) {
      this.toastService.warning('Não foi encontrado nenhum aluno com estes critérios');
      this.spinnerService.hide();
      return;
    }

    this.spinnerService.hide();

  }

  // public filtrarAluno(nome: string) {
  //   const turmasEnvolvidosFiltro: TurmaEnvolvido[] = [];
  //   const turmasEnvolvidosOriginal: TurmaEnvolvido[] = this.turmasEnvolvidos;
  //   let codigoTurmaVar = 0;
  //   let contadorTurasFinal = -1;
  //   for (let i = 0; i < turmasEnvolvidosOriginal.length; i++) {
  //     var envolvidosFiltro: Envolvido[] = [];
  //     for (let j = 0; j < turmasEnvolvidosOriginal[i].envolvidos.length; j++) {
  //       if (turmasEnvolvidosOriginal[i].envolvidos[j].nome.includes(nome.toUpperCase())) {
  //         if (codigoTurmaVar != turmasEnvolvidosOriginal[i].codigoTurma) {
  //           contadorTurasFinal++;
  //           turmasEnvolvidosFiltro.push(turmasEnvolvidosOriginal[i]);
  //           codigoTurmaVar = turmasEnvolvidosOriginal[i].codigoTurma;
  //         }
  //         envolvidosFiltro.push(turmasEnvolvidosOriginal[i].envolvidos[j]);
  //       }
  //     }
  //     if (envolvidosFiltro.length != 0)
  //       turmasEnvolvidosFiltro[contadorTurasFinal].envolvidos = envolvidosFiltro;
  //   }
  //   this.turmasEnvolvidos = turmasEnvolvidosFiltro;
  //   // this.spinnerService.hide();
  // }

  // public filtrarAlunoRaNome(event: any){
  //   const regexNumeros = /\d$/;
  //   const regexLetras = /^[a-zA-Z]+$/;
  //   const nomeRa = this.form.nomeAluno.value;

  //   if (event == undefined)
  //     return;
  //   if (nomeRa == null)
  //     return


  //   if(regexNumeros.test(nomeRa)){
  //     this.ra = nomeRa;
  //     return ;
  //   }else if(regexLetras.test(nomeRa)){
  //     this.filtrarAluno(nomeRa);
  //     return ;
  //   }else {
  //     // this.toastr.error("O campo de busca de alunos aceita apenas RA ou Nome, nunca os dois.")
  //     return ;
  //   }

  // }


  public setObjetoEnvolvidos(){

    let buscar = new BuscarEnvolvidos();
    buscar.codigoDiretoria = this.filtros?.codigoDiretoria;
    buscar.codigoEscola = this.filtros?.codigoEscola;
    buscar.codigoTurma = this.filtros?.codigoTurma ;
    buscar.codigoTurno = this.filtros?.codigoTurno ;
    buscar.nome = this.nome;
    buscar.ra = this.ra;
    buscar.anoAtual = this.filtros.anoAtual!;
    return buscar;
  }

  public ValidaRaNome(nomeRa?:string){
    const regexNumeros = /^[0-9]+$/;
    const regexLetras = /^[\p{L}\p{M}\s]+$/u;
    // const regexLetras = /^[\p{L}\p{M}]+$/u;
    this.ra = undefined;
    this.nome = undefined;


    if(!nomeRa || nomeRa === ""){
      return false;
    }


    if(regexNumeros.test(nomeRa)){
      this.ra = nomeRa;
      return false;
    }else if(regexLetras.test(nomeRa)){
      this.nome = nomeRa;
      return false;
    }else {
      this.toastr.error("O campo de busca de alunos aceita apenas RA ou Nome, nunca os dois.")
      return true;
    }

  }


}
