//Principais
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgSelectConfig } from "@ng-select/ng-select";
import { DataTableDirective } from "angular-datatables";
import { ADTSettings } from "angular-datatables/src/models/settings";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subject, lastValueFrom } from "rxjs";

//Métodos
import { TipoPessoa } from 'src/app/shared/enums/envolvidos/TipoPessoa';
import { TurmaAluno } from "src/app/shared/models/aluno/TurmaAluno";
import { ArquivoAzure } from 'src/app/shared/models/arquivo/ArquivoAzure';
import { Envolvido } from 'src/app/shared/models/chamados/envolvido';
import { ListarChamados } from 'src/app/shared/models/chamados/listar-chamados';
import { DefaultResponse } from 'src/app/shared/models/DefaultResponse';
import { Diretoria } from 'src/app/shared/models/diretoria/Diretoria';
import { Aluno } from 'src/app/shared/models/envolvidos/aluno';
import { Funcionario } from 'src/app/shared/models/envolvidos/funcionario';
import { Escola } from 'src/app/shared/models/escola/Escola';
import { Polo } from "src/app/shared/models/polo/Polo";
import { AtividadePsicologo } from 'src/app/shared/models/psicologo/AtividadePsicologo';
import { Psicologo } from "src/app/shared/models/psicologo/Psicologo";
import { VisitaPsicologo } from "src/app/shared/models/psicologo/VisitaPsicologo";
import { AtribuicaoLoginSed, UserLoginSed } from "src/app/shared/models/sed/UserLogin";

//Serviços
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from "src/app/shared/auth/services/auth.service";
import { AtividadePsicologoDeslocamento } from 'src/app/shared/models/atividade-psicologo/atividade-psicologo-deslocamento';
import { AgendamentoPrevencaoDto, AgendamentoPrevencaoEnvolvidoDto } from 'src/app/shared/models/psicologo/AgendamentoPrevencaoDto';
import { Turma } from 'src/app/shared/models/turma/Turma';
import { AlunoService } from 'src/app/shared/services/alunos.service';
import { ArquivoService } from 'src/app/shared/services/arquivo.service';
import { ConvivaErrorHandlingService } from "src/app/shared/services/convivaErrorHandlingService";
import { EscolaService } from "src/app/shared/services/escola.service";
import { FuncionarioService } from 'src/app/shared/services/funcionarios.service';
import { PsicologoService } from "src/app/shared/services/psicologo.service";
import { GenericValidator } from 'src/app/shared/validations/genericValidators';

@Component({
  templateUrl: './psicologo-visitas-prevencao.component.html',
  styleUrls: ['./psicologo-visitas-prevencao.component.scss']
})

export class PageVisitaPsicologoPrevencaoComponent implements OnInit, OnDestroy {

  @Input({ required: true }) temDeslocamento: boolean;

  // Variaveis de Login
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;

  public dtOptions: any = {};
  public dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();
  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  public hideAfterLoadTable: boolean;
  public hideAfterLoadTableAlunos: boolean;
  public isDtInitialized: boolean = false;
  public Passo: number = 1;

  constructor(
    private config: NgSelectConfig,
    private escolaService: EscolaService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private authService: AuthService,
    private psicologoService: PsicologoService,
    private arquivoService: ArquivoService,
    private alunoService: AlunoService,
    // private envolvidoService:EnvolvidoService,
    private funcionarioService: FuncionarioService,
    private genericValidate: GenericValidator,
    private route: ActivatedRoute,
    private genericValidator: GenericValidator,
  ) {
    this.config.notFoundText = 'Não encontrado';

    // Dados usuário logado
    this.userLogado = this.authService.getUser();
    if (this.userLogado != null && !this.userLogado.flPsicologo) {
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
    }

    this.route.params.subscribe((params) => {
      if (params.temDeslocamento === '1') {
        this.temDeslocamento = true;
      }
      else {
        this.temDeslocamento = false;
      }
    });
  }

  public polos: Polo[] = [];
  public diretorias: Diretoria[] = [];
  public escolas: Escola[] = [];
  public psicologos: Psicologo[] = [];
  public lstPsicologos: Psicologo[] = [];
  public lstTurmas: Turma[] = [];
  public lstTurmasSelecionadas: Turma[] = [];
  public lstTurmasAtivas: number[] = [];
  public lstTurmaAluno: TurmaAluno[] = [];
  public lstFuncionariosSelecionados: Funcionario[] = [];
  public lstOutros: string[] = [];
  public lstFuncionarios: Funcionario[] = [];
  public lstOcorrenciasAgendadas: ListarChamados[] = [];
  public OcorrenciaSelecionada: ListarChamados;
  public lstAgendamentosPrevencao: AgendamentoPrevencaoDto[] = [];

  tabulatorColumns = [
    { title: 'Polo', field: 'codigoPolo', width: 80, hozAlign: "center", headerHozAlign: "center" },
    { title: 'Diretoria Escolar', field: 'nomeDiretoria', width: 150, hozAlign: "center", headerHozAlign: "center" },
    { title: 'Envolvido', field: 'envolvido', hozAlign: "center", width: 150, headerHozAlign: "center" },
    { title: 'Psicologo', field: 'nomePsicologo', width: 325, hozAlign: "center", headerHozAlign: "center" },
    { title: 'Data/Hora', field: 'stringDataAgendamento', hozAlign: "center", headerHozAlign: "center" },
    {
      title: 'Ações', field: 'visualizar', width: 100, hozAlign: "center", formatter: function (cell: any, formatterParams: any, onRendered: any) {
        return `     <label>
                        <input
                          type="checkbox"
                          class="checkbox"
                        /><i></i>
                      </label>`;
      }, headerHozAlign: "center",
      cellClick: (e: any, cell: any) => {
        const row = cell.getRow();
        const target = e.target as HTMLElement;
        const rowElement = row.getElement();
        if (target && target.matches('input.checkbox')) {
          // Desmarca todos os outros checkboxes
          const rows = cell.getTable().getRows();
          rows.forEach((row: any) => {
            const checkbox = row.getElement()
              .querySelector('input.checkbox') as HTMLInputElement;
            if (row !== cell.getRow() && checkbox) {
              checkbox.checked = false;
              row.getElement().classList.remove('tabulator-selected');

            }
          });

          rowElement.classList.toggle('tabulator-selected');
          this.toggleAgendamento(e.target.checked, row.getData());

        }


      }
    }
  ]
  public agendamentoSelecionado?: AgendamentoPrevencaoDto;

  public files: ArquivoAzure[] = [];
  public envolvidos: Envolvido[] = [];
  public lstEnvolvidos: Envolvido[] = [];
  public lstEnvolvidosSelecionados: Envolvido[] = [];
  public alunos: Aluno[] = [];
  public aluno: Aluno = <Aluno>{};
  public alunosSelecionados: Aluno[] = [];
  public escolasModalAlunos: Escola[] = [];
  public modelDeslocamento: AtividadePsicologoDeslocamento | null;;
  public envolvidoExclusao?: Envolvido;
  public NomePsicologo?: string;
  public showGridTurma: boolean;
  public encaminhamentos = [{ id: 0, name: 'Sem Encaminhamento' }, { id: 1, name: 'Hospital Geral' }, { id: 2, name: 'Pronto Socorro' }, { id: 3, name: 'CRAS' }, { id: 4, name: 'UBS' }, { id: 5, name: 'UPA' }, { id: 6, name: 'CAISM' }, { id: 7, name: 'CAPS' }];
  public lstAtividadeRealizada = [{ id: 1, name: "Conversa/atividade individual com estudante" },
  { id: 2, name: "Conversa/atividade em grupo" },
    // { id: 3, name: "Conversa/atividade com profissionais" }
  ];
  public lstGrupos = [{ id: 1, name: "Turmas participantes da atividade" }, { id: 2, name: "Equipe escolar" }, { id: 3, name: "Outros" }];
  public tipoPessoaAdd = [{
    descricao: 'Outros',
    id: 1
  }, {
    descricao: 'Profissional',
    id: 2
  }]
  public mostrarAlunos = false;
  public mostrarGrupos = false;
  public mostrarProffisionais = false;
  public showModalGrupos = false;
  public mostrarDivTurmas = false;
  public mostrarDivEquipeEscolar = false;
  public mostrarDivOutros = false;
  public showDropAuxiliar = false;
  public submittedModal = false;
  public submittedModalAluno = false;
  public showModal: boolean = false;
  public showModalAluno: boolean = false;
  public showModalEquipeEscolar = false;
  public showModalOutros = false;
  public showModalExcluirEnvolvido = false;
  public showDropFuncionarios: boolean = false;
  public descricaoExclusao = '';
  public duracaoVisita = 15;
  public nomePsicologoSelecionado?: string;
  public dataVisita?: Date;
  public atividadeRealizadaSelecionado?: string;
  public objetivoAcao?: string;
  public avaliacaoResultado?: string;
  public impactoAcao?: string;
  public enableEnviarAprovacao: boolean = false;
  public validaBuscaRA = false;
  public qtdeCaracteresAvalia: number = 0;
  public qtdeCaracteresImpacto: number = 0;

  public atividadesDesenvolvidas = [
    { id: 1, name: 'Escuta/observação' },
    { id: 2, name: 'Coleta de dados/informação' },
    { id: 3, name: 'Reunião com equipe gestora' },
    { id: 4, name: 'Reunião com Psicólogo' },
    { id: 5, name: 'Reunião com o Supervisor' },
    { id: 6, name: 'Reunião com professores/administrativo' },
    { id: 7, name: 'Reunião com alunos' },
    { id: 8, name: 'Outras' },
  ];

  public acoesPreventiva = [
    { id: 1, name: 'Alinhamento' },
    { id: 2, name: 'Devolutiva' },
    { id: 3, name: 'Orientação' },
    { id: 4, name: 'Planejamento' },
    { id: 5, name: 'Construção de propostas com gestores/PEC/POC/Vice/GOE/Comissão Gremista' },
    { id: 6, name: 'Produção do trabalho/construção de materiais' },
    { id: 7, name: 'Aplicação/realização do trabalho desenvolvido' },
    { id: 8, name: 'Outras' },
  ];

  public formVisita!: FormGroup;
  public formModalEquipeEscolar!: FormGroup;
  public formModalOutros!: FormGroup;
  public formModal!: FormGroup;
  public formModalAluno!: FormGroup;
  public get form() { return this.formVisita.controls; }
  public get formEquipeEscolar() { return this.formModalEquipeEscolar.controls; }
  public get formOutros() { return this.formModalOutros.controls; }
  public get formModalControls() { return this.formModal.controls; }
  public get modalFormAluno() { return this.formModalAluno.controls; }
  public submitted: boolean;
  public submittAba1: boolean = false;
  public submittAba2: boolean = false;

  public async ngOnInit() {

    this.dtOptions = {
      deferRender: true,
      destroy: true,
      processing: true,
      paging: true,
      dom: 'Bfrtip',
      order: [
        [0, 'asc'],
        [6, 'asc']
      ],
      language: {
        url: 'https://cdn.datatables.net/plug-ins/1.11.5/i18n/pt-BR.json'
      },
      lengthMenu: [
        [10, 25, 50, -1],
        ['10 linhas', '25 linhas', '50 linhas', 'Tudo']
      ],
      buttons: [
        'pageLength',
        {
          extend: 'print',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6]
          }
        },
        {
          extend: 'excel',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6]
          }
        },
        {
          extend: 'pdf',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6]
          }
        }
      ],
    };

    //#region Forms

    this.formVisita = new FormGroup({
      codigoPolo: new FormControl(null, [Validators.required]),
      codigoDiretoria: new FormControl(null, [Validators.required]),
      nomeDiretoria: new FormControl(null, [Validators.required]),
      codigoEscola: new FormControl(null, [Validators.required]),
      nomeEscola: new FormControl(null, [Validators.required]),
      codigoPsicologo: new FormControl(null, [Validators.required]),
      dataVisita: new FormControl(null, [Validators.required]),
      codigoAtividade: new FormControl(null, [Validators.required]),
      codigoAcaoPreventiva: new FormControl(null, [Validators.required]),
      tipoGrupo: new FormControl(null, [Validators.required]),
      codigoChamado: new FormControl(null, [Validators.required]),
      objetivoAcao: new FormControl(null, [Validators.required]),
      avaliacaoResultado: new FormControl(null, [Validators.required]),
      impactoAcao: new FormControl(null, [Validators.required]),
      arquivos: new FormControl(null, [Validators.required]),
    })

    this.formModalAluno = new FormGroup({
      codigoDiretoriaModal: new FormControl(null, [Validators.required]),
      codigoEscolaModal: new FormControl(null, [Validators.required]),
      raAlunoModalDrop: new FormControl(null),
      raNomeAluno: new FormControl(null, [Validators.required])
    })

    //#endregion

    this.formModal = new FormGroup({
      tipoPessoa: new FormControl(null),
      cpfBusca: new FormControl(null, [Validators.required, this.genericValidator.ValidaCpf]),
      cpfProfissionalSelecionado: new FormControl(null),
      dsOutros: new FormControl(null)
    })


    if (this.userLogado?.flPsicologo) {
      await this.getPoloDiretoriaPsicologo();
    }
    else {
      if (this.atribuicoesLogin.NumeroComportamento == 1) {
        await this.getPolosAdmin();
      }
      if (this.atribuicoesLogin.NumeroComportamento == 2) {
        await this.getPolosPerfilDiretor();
      }
      if (this.atribuicoesLogin.NumeroComportamento == 3 || this.atribuicoesLogin.NumeroComportamento == 4) {
        await this.getPolosPerfilProfessor();
      }
    }

    if (this.userLogado?.codigoPolo != 0 && this.userLogado?.codigoPolo != undefined) {
      await this.getDiretoriasPorPolo();
      await this.getEscolasPorDiretoria();
    }
    await this.getPsicologosPolo();


  }

  public ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  //#region Polos

  public async getPoloDiretoriaPsicologo() {

    var response = await lastValueFrom(this.psicologoService.getPoloPsicologo(this.userLogado!.cpf)).catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });

    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = [response.data];
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    await this.getDiretoriasPorPolo();
    await this.getPsicologosPolo();
  }

  public async getPolosAdmin() {
    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getPolos()).catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });

    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = response.data;
    this.spinnerService.hide();
    if (this.userLogado?.codigoPolo != 0) {
      this.ativaPolo();
    }
    this.spinnerService.hide();
    await this.getPsicologosPolo();
  }

  public async getPolosPerfilDiretor() {
    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getPolos(this.userLogado?.DadosUsuario[0].CodigoDiretoria)).catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });

    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = response.data;
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    if (this.userLogado?.DadosUsuario[0].CodigoDiretoria != 0) {
      var todasDiretoriasDoUsuario: Diretoria[] = [];
      this.userLogado?.DadosUsuario.forEach(item => {
        var diretoria = new Diretoria();
        diretoria.codigoDiretoria = item.CodigoDiretoria;
        diretoria.nomeDiretoria = item.NomeDiretoria;
        todasDiretoriasDoUsuario.push(diretoria);
      });
      this.diretorias = todasDiretoriasDoUsuario;

      if (this.diretorias.length == 1) {
        this.form.codigoDiretoria.setValue(this.userLogado?.DadosUsuario[0].CodigoDiretoria);
      } else {
        this.form.codigoDiretoria.enable();
      }

      if (this.userLogado?.codigoDiretoria && this.userLogado.codigoDiretoria != 0) {
        this.form.codigoDiretoria.setValue(this.userLogado?.codigoDiretoria);
      }

      this.form.codigoEscola.enable();
      await this.getEscolasPorDiretoria();
      await this.getPsicologosPolo();
    }

    this.spinnerService.hide();
  }

  public async getPolosPerfilProfessor() {
    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getPolos(this.userLogado?.DadosUsuario[0].CodigoDiretoria)).catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });

    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = response.data;
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    var dadosUsuario = this.userLogado?.DadosUsuario[0];
    if (dadosUsuario?.CodigoDiretoria != 0 && dadosUsuario?.CodigoEscola != 0) {
      this.userLogado?.DadosUsuario.forEach(item => {
        var diretoria = new Diretoria();
        diretoria.codigoDiretoria = item.CodigoDiretoria;
        diretoria.nomeDiretoria = item.NomeDiretoria;
        this.diretorias = [diretoria];

        var escola = new Escola();
        escola.codigoEscola = item.CodigoEscola;
        escola.nomeEscola = item.NomeEscola;
        this.escolas = [escola];
      });

      this.form.codigoDiretoria.setValue(this.userLogado?.DadosUsuario[0].CodigoDiretoria);
      this.form.codigoEscola.setValue(this.userLogado?.DadosUsuario[0].CodigoEscola);
    }
    this.spinnerService.hide();
    await this.getPsicologosPolo();
  }

  public ativaPolo() {
    if (this.userLogado?.codigoPolo != undefined)
      this.formVisita.get('codigoPolo')?.setValue(this.userLogado?.codigoPolo);

    if (this.userLogado !== null)
      this.authService.setUser(this.userLogado);
  }

  public async cambioPolo() {
    const codigoPO = this.formVisita.get('codigoPolo')?.value;
    if (this.userLogado !== null) {
      this.userLogado.codigoPolo = codigoPO == null ? 0 : codigoPO;
      this.authService.setUser(this.userLogado);
    }
    this.form.codigoDiretoria.enable();
    this.form.codigoEscola.enable();
    this.zerarEscola();
    await this.getDiretoriasPorPolo();
  }

  public async getPsicologosPolo() {
    if (this.userLogado?.codigoPerfil == 1634) { //Codigo Perfil Psicologo
      this.spinnerService.show();
      var response = await lastValueFrom(
        this.psicologoService.getPsicologoPorCPF(this.userLogado.cpf))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
      if (!response.isSucess) {
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.spinnerService.hide();
      this.lstPsicologos = [];
      var psicologo: Psicologo = new Psicologo;
      psicologo.nomePsicologo = response.data.nome;
      psicologo.codigoPsicologo = response.data.codigoPsicologo;
      this.lstPsicologos.push(psicologo);
    }
    else {
      this.spinnerService.show();
      var response = await lastValueFrom(this.psicologoService.getPsicologosPorPolo(this.formVisita.get('codigoPolo')!.value))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
      if (!response.isSucess) {
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.spinnerService.hide();
      this.lstPsicologos = response.data;
    }
  }

  //#endregion

  //#region Diretorias

  public async getDiretoriasPorPolo(diretoriaLogada: boolean = false) {
    if (this.userLogado?.codigoPolo) {
      this.zerarDiretoria();
      this.spinnerService.show();
      var response = await lastValueFrom(this.psicologoService.getDiretoriaPorPolo(this.userLogado!.codigoPolo))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
      if (!response.isSucess) {
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      await this.getEscolasPorDiretoria();
      await this.getPsicologosPolo();
      this.diretorias = response.data;
      this.form.codigoDiretoria.enable();
      this.ativaDiretoria();
      this.spinnerService.hide();
    }

    if (this.userLogado !== null) {
      this.userLogado.codigoDiretoria = this.userLogado.codigoDiretoria == null ? 0 : this.userLogado.codigoDiretoria;
      this.authService.setUser(this.userLogado);
    }
  }

  public ativaDiretoria() {
    if (this.diretorias != undefined && this.userLogado?.codigoDiretoria != 0) {
      const diretoria = this.diretorias.find(s => s.codigoDiretoria == this.userLogado?.codigoDiretoria)
      if (this.userLogado?.codigoDiretoria != undefined && diretoria != undefined) {
        this.formVisita.get('codigoDiretoria')?.setValue(this.userLogado?.codigoDiretoria);
      }
    }
    if (this.userLogado !== null)
      this.authService.setUser(this.userLogado);
  }

  public zerarDiretoria() {
    this.diretorias = [];
    this.formVisita.get('codigoDiretoria')?.patchValue(null);
  }

  public async trocaDiretoria() {
    const codigoDiretoria = this.formVisita.get('codigoDiretoria')?.value;
    if (this.userLogado !== null && codigoDiretoria != null) {
      this.userLogado.codigoDiretoria = codigoDiretoria == null ? 0 : codigoDiretoria;
      this.authService.setUser(this.userLogado);
    }
    if (codigoDiretoria != null)
      await this.getEscolasPorDiretoria();
    else
      this.escolas = [];
    this.form.codigoEscola.setValue(null);
  }

  //#endregion

  //#region Escolas

  public async getEscolasPorDiretoria() {
    if (this.userLogado !== null) {
      this.userLogado.codigoEscola = this.userLogado.codigoEscola == null ? 0 : this.userLogado.codigoEscola;
      this.authService.setUser(this.userLogado);
    }

    if (this.userLogado?.codigoDiretoria) {
      this.spinnerService.show();
      this.zerarEscola();
      var response = await lastValueFrom(this.escolaService.getEscolasPorCodigoDiretoria(this.userLogado!.codigoDiretoria))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
      if (!response.isSucess) {
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.escolas = response.data;
      this.ativaEscola();
      this.spinnerService.hide();
    }
  }

  public ativaEscola() {
    if (this.escolas != undefined && this.userLogado?.codigoEscola != 0) {
      const escola = this.escolas.find(s => s.codigoEscola == this.userLogado?.codigoEscola)
      if (this.userLogado?.codigoEscola != undefined && escola != undefined) {
        this.formVisita.get('codigoEscola')?.setValue(this.userLogado?.codigoEscola);
      }
    }
  }

  public zerarEscola() {
    this.escolas = [];
    this.formVisita.get('codigoEscola')?.patchValue(null);
  }

  //#endregion


  public async OnSalvar() {
    this.spinnerService.show();
    const dir = this.diretorias.filter(r => r.codigoDiretoria == this.formVisita.get('codigoDiretoria')?.value)[0];
    const esc = this.escolas.filter(r => r.codigoEscola == this.formVisita.get('codigoEscola')?.value)[0];
    const codigoAtividade = this.formVisita.get('codigoAtividade')?.value;
    if (codigoAtividade == 3 || codigoAtividade == 1) {
      this.lstEnvolvidosSelecionados = this.mappingArrayEnvolvidos();
    }

    const objAtendimentoPsicologo: AtividadePsicologo = {
      codigoPolo: this.formVisita.get('codigoPolo')?.value,
      codigoDiretoria: this.formVisita.get('codigoDiretoria')?.value,
      nomeDiretoria: dir.nomeDiretoria,
      codigoEscola: this.formVisita.get('codigoEscola')?.value,
      nomeEscola: esc.nomeEscola,
      codigoPsicologo: this.formVisita.get('codigoPsicologo')?.value,
      codigoChamado: this.formVisita.get('codigoChamado')?.value,
      dataAtividadePsicologo: this.formVisita.get('dataVisita')?.value,
      timeAtividadePsicologo: this.duracaoVisita.toString(),
      tipoAcaoAtividade: 1, //Prevenção Psicologo
      tipoAtividadeRealizada: codigoAtividade,
      descricaoRespostaPerguntaObjetivoAcao: this.formVisita.get('objetivoAcao')?.value,
      descricaoRespostaPerguntaAvaliacaoResultadoAcao: this.formVisita.get('avaliacaoResultado')?.value,
      descricaoRespostaPerguntaImpactoAcaoRealizada: this.formVisita.get('impactoAcao')?.value,
      tipoAcao: 1, //Inserir
      codigoUsuario: this.userLogado?.flPsicologo ? this.userLogado.codigoUsuario : this.atribuicoesLogin.CodigoUsuario,
      codigoPerfil: this.userLogado?.flPsicologo ? this.userLogado.codigoPerfil : this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.userLogado?.flPsicologo ? this.userLogado.nomePerfil : this.atribuicoesLogin.NomePerfil,
      arquivos: this.files,
      codigoAgendamento:this.agendamentoSelecionado?.codigoAgendamento,
      listaEnvolvidos: this.lstEnvolvidosSelecionados
    };
    if (this.temDeslocamento) {
      objAtendimentoPsicologo.codigoDeslocamentoDiretoriaOrigem = this.modelDeslocamento?.codigoDiretoriaOrigem;
      objAtendimentoPsicologo.nomeDeslocamentoDiretoriaOrigem = this.modelDeslocamento?.nomeDiretoriaOrigem;
      objAtendimentoPsicologo.codigoDeslocamentoEscolaOrigem = this.modelDeslocamento?.codigoEscolaOrigem;
      objAtendimentoPsicologo.nomeDeslocamentoEscolaOrigem = this.modelDeslocamento?.nomeEscolaOrigem;
      objAtendimentoPsicologo.codigoDeslocamentoDiretoriaDestino = this.modelDeslocamento?.codigoDiretoriaDestino;
      objAtendimentoPsicologo.nomeDeslocamentoDiretoriaDestino = this.modelDeslocamento?.nomeDiretoriaDestino;
      objAtendimentoPsicologo.codigoDeslocamentoEscolaDestino = this.modelDeslocamento?.codigoEscolaDestino;
      objAtendimentoPsicologo.nomeDeslocamentoEscolaDestino = this.modelDeslocamento?.nomeEscolaDestino;
      objAtendimentoPsicologo.codigoTipoTransporte = this.modelDeslocamento?.codigoTipoTransporte;
      objAtendimentoPsicologo.horaDeslocamentoSaida = this.modelDeslocamento?.horarioSaida.substring(0, 5);
      objAtendimentoPsicologo.horaDeslocamentoChegada = this.modelDeslocamento?.horarioChegada.substring(0, 5);
    }

    var responseCadastro = await lastValueFrom(this.psicologoService.setCadastrarAtividadePsicologo(objAtendimentoPsicologo))
      .catch((err) => {
        this.spinnerService.hide();
        return this.ConvivaErrors.handleResultError(err);
      });
    if (!responseCadastro.isSucess) {
      this.toastr.error(responseCadastro.message);
      this.spinnerService.hide();
      return;
    }
    const visita: VisitaPsicologo = responseCadastro.data;
    this.toastr.success('Visita do Psicologo cadastrada com sucesso.');
    this.toastr.success('Redirecionando para o ínicio em 5 segundos.');
    this.enableEnviarAprovacao = false;
    setTimeout(() => {
      window.location.reload();
    }, 5000);
    this.spinnerService.hide();
  }

  private mappingArrayEnvolvidos(): Envolvido[] {
    var envolvidosNaAtividade: Envolvido[] = [];
    var tipoAtividade: number = this.formVisita.get('codigoAtividade')?.value;

    if (tipoAtividade == 3) { //tipo grupo
      const funcionarios = this.lstFuncionariosSelecionados.map((x: Funcionario) => {
        var envolvidoAtv: Envolvido = new Envolvido();
        envolvidoAtv.cpf = x.cpf;
        envolvidoAtv.tipoPessoa = TipoPessoa.EquipeEscolar;
        envolvidoAtv.tipoEnvolvido = 0;
        return envolvidoAtv;
      });
      const outros = this.lstOutros.map(x => {
        var envolvidoAtv: Envolvido = new Envolvido();
        envolvidoAtv.dsOutros = x;
        envolvidoAtv.tipoPessoa = TipoPessoa.Outros;
        envolvidoAtv.tipoEnvolvido = 0;
        return envolvidoAtv;
      });
      envolvidosNaAtividade = funcionarios.concat(outros);
    }
    if (tipoAtividade == 1) {
      const alunos = this.alunosSelecionados.map((x: Aluno) => {
        var envolvidoAtv: Envolvido = new Envolvido();
        envolvidoAtv.codigoAluno = x.codigoAluno;
        envolvidoAtv.codigoTurma = x.codigoTurma;
        envolvidoAtv.tipoPessoa = TipoPessoa.Aluno;
        envolvidoAtv.ra = x.ra;
        envolvidoAtv.tipoEnvolvido = 0;
        return envolvidoAtv;
      });
      envolvidosNaAtividade = alunos;
    }
    return envolvidosNaAtividade;
  }

  public ValidaCampos(aba: number) {
    var erro = false;
    if (aba == 1) {
      this.submittAba1 = true;
      var polo = this.formVisita.get('codigoPolo')?.value;
      var diretoria = this.formVisita.get('codigoDiretoria')?.value;
      var escola = this.formVisita.get('codigoEscola')?.value;
      var psicologo = this.formVisita.get('codigoPsicologo')?.value;
      var dataVisita = this.formVisita.get('dataVisita')?.value;
      var codigoAtividade = this.formVisita.get('codigoAtividade')?.value;
      if (polo == 0 || polo == undefined) {
        erro = true;
      }
      if (diretoria == 0 || diretoria == undefined) {
        erro = true;
      }
      if (escola == 0 || escola == undefined) {
        erro = true;
      }
      if (psicologo == "" || psicologo == null || psicologo == undefined) {
        erro = true;
      }
      if (dataVisita == "" || dataVisita == null || dataVisita == undefined) {
        erro = true;
      }
      if (codigoAtividade == "" || codigoAtividade == null || codigoAtividade == undefined) {
        erro = true;
      }
      if (this.lstEnvolvidosSelecionados.length == 0 &&
        this.lstFuncionariosSelecionados.length == 0 &&
        this.lstOutros.length == 0 &&
        this.alunosSelecionados.length == 0 &&
        !this.agendamentoSelecionado ) {
        this.toastr.error("Favor de preencher um envolvido");
        erro = true;
      }
    }
    if (aba == 2) {
      this.submittAba2 = true;
      var objetivoAcao = this.formVisita.get('objetivoAcao')?.value;
      var avaliacaoResultado = this.formVisita.get('avaliacaoResultado')?.value;
      var impactoAcao = this.formVisita.get('impactoAcao')?.value;
      var arquivos = this.formVisita.get('arquivos')?.value;

      if (objetivoAcao == "" || objetivoAcao == null || objetivoAcao == undefined) {
        erro = true;
      }
      if (avaliacaoResultado == "" || avaliacaoResultado == null || avaliacaoResultado == undefined || this.qtdeCaracteresAvalia < 150 || this.qtdeCaracteresAvalia > 500) {
        erro = true;
      }
      if (impactoAcao == "" || impactoAcao == null || impactoAcao == undefined || this.qtdeCaracteresImpacto < 150 || this.qtdeCaracteresImpacto > 500) {
        erro = true;
      }
      if (arquivos == "" || arquivos == null || arquivos == undefined || this.files.length == 0) {
        erro = true;
      }
    }

    if (erro) return false;
    else return true;
  }

  public OnLimpar() {
    this.spinnerService.show();
    this.formVisita.get('codigoPsicologo')?.patchValue(null);
    this.formVisita.get('dataVisita')?.patchValue(null);
    this.formVisita.get('codigoAtividade')?.patchValue(null);
    this.formVisita.get('codigoAcaoPreventiva')?.patchValue(null);
    this.formVisita.get('tipoGrupo')?.patchValue(null);
    this.formVisita.get('objetivoAcao')?.patchValue(null);
    this.formVisita.get('avaliacaoResultado')?.patchValue(null);
    this.formVisita.get('impactoAcao')?.patchValue(null);

    this.files = [];
    this.getPsicologosPolo();
    this.OnLimparEnvolvidos();
    this.spinnerService.hide();
  }

  public OnLimparEnvolvidos() {
    this.spinnerService.show();
    this.lstTurmas = [];
    this.lstTurmasAtivas = [];
    this.lstTurmaAluno = [];
    this.lstEnvolvidos = [];
    this.lstTurmasAtivas = [];
    this.lstFuncionarios = [];
    this.lstFuncionariosSelecionados = [];
    this.lstEnvolvidosSelecionados = [];
    this.alunosSelecionados = [];
    this.files = [];
    this.envolvidos = [];
    this.showGridTurma = false;
    this.mostrarDivEquipeEscolar = false;
    this.mostrarDivTurmas = false;
    this.mostrarDivOutros = false;
    this.spinnerService.hide();
  }

  public async caregarTurmas() {
    this.submitted = true;
    const codigoEs = this.formVisita.get('codigoEscola');

    if (codigoEs?.value != null) {
      this.spinnerService.show();
      const result = await lastValueFrom(await this.escolaService.getTurmasPorCodigoCie(codigoEs?.value, 0))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
      if (!result.isSucess) {
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }
      this.lstTurmas = result.data;
      this.spinnerService.hide();

      this.showGridTurma = true;
      if (this.lstTurmas.length == 0) {
        this.toastr.warning("Não existem Turmas para essa pesquisa");
        if (this.dtElement.dtInstance != null) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next(this.dtOptions);
            this.hideAfterLoadTable = true;
          });
        }
      }
      else {
        this.hideAfterLoadTable = false;
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next(this.dtOptions);

          });
        } else {
          this.isDtInitialized = true;

          if (this.dtElement.dtInstance != null) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            });
          }
          this.dtTrigger.next(this.dtOptions);
        }
      }
    }
  }

  public async onCheckTurma(codigoTurma: number, descricaoTurma: string) {
    var existeEmLista: boolean = false;
    this.lstTurmasAtivas.forEach(element => {
      if (element == codigoTurma) {
        existeEmLista = true;
      }
    });
    if (existeEmLista) {
      const index = this.lstTurmasAtivas.indexOf(codigoTurma)
      this.lstTurmasAtivas.splice(index, 1);
      this.lstTurmasSelecionadas.splice(index, 1);
      this.lstEnvolvidosSelecionados.splice(index, 1);
    } else {
      this.lstTurmasAtivas.push(codigoTurma);

      var turma = this.lstTurmas.find((fun) => fun.codigoTurma === codigoTurma);
      if (turma != null)
        this.lstTurmasSelecionadas.push(turma);

      const objetoEnvolvido: Envolvido = {
        tipoPessoa: 4,
        tipoEnvolvido: 0,
        nome: descricaoTurma,
        codigoTurma: codigoTurma,
        numeroClasse: turma?.numeroClasse,
        nomeTipoEnsino: turma?.nomeTipoEnsino,
        numeroSerie: turma?.numeroSerie,
        descricaoTurma: turma?.descricaoTurma
      };

      objetoEnvolvido.idTemporario = this.envolvidos.reduce((maxId, envolvido) => Math.max(maxId, envolvido.idTemporario || 0), 0) + 1;
      this.envolvidos.push(objetoEnvolvido);
      this.lstEnvolvidosSelecionados.push(objetoEnvolvido);
    }
  }

  public onCheckTodosFalse() {
    this.lstTurmaAluno.forEach(element => {
      element.presenca = false;
    });
  }

  public onCheckTodosTrue() {
    this.lstTurmaAluno.forEach(element => {
      element.presenca = true;
    });
  }

  public concatenaDataHora(data: string, hora: string): string {
    return `${data + " " + hora}`
  }

  public async OnFileChange(event: any) {
    const maxFileSize = 31 * 1024 * 1024; // 30 MB
    if (event.target.files.length > 0) {
      this.spinnerService.show();
      const formData = new FormData();
      const files = event.target.files;
      for (const file of files) {
        if (file.size > maxFileSize) {
          this.toastr.warning('O tamanho máximo do arquivo é 30 MB.');
          const fileInput = document.getElementById('arquivos') as HTMLInputElement;
          if (fileInput) {
            fileInput.value = '';
          }
          this.spinnerService.hide();
          return;
        }
        if (file.type == "image/jpg" ||
          file.type == "image/jpeg" ||
          file.type == "image/png" ||
          file.type == "application/pdf" ||
          file.type == "application/ppt" ||
          file.type == "application/pptx" ||
          file.type == "application/potx" ||
          file.type == "application/ppsx" ||
          file.type == "application/odp" ||
          file.type == "audio/mpeg" ||
          file.type == "video/mp4"
        ) {
          formData.append(file.name, file);
        }
        else {
          this.toastr.warning('Só é possivel subir arquivos .jpg, .jpeg , .png, .pdf, .mp3 e .mp4');
          const fileInput = document.getElementById('arquivos') as HTMLInputElement;
          if (fileInput) {
            fileInput.value = '';
          }
          this.spinnerService.hide();
          return;
        }
      }
      const result = await lastValueFrom(await this.arquivoService.setArquivoBase64(formData))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
      if (!result.isSucess) {
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      } else {
        this.files.push(...result.data);
        event.target.value = '';
      }
      this.spinnerService.hide();
    }
  }

  public removeFile(index: number) {
    this.files.splice(index, 1);
    const fileInput = document.getElementById('arquivos') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }

  removeUniqueId(fileName: string): string {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}-/;
    return fileName.replace(regex, '');
  }

  public async setPsicologo() {
    this.spinnerService.show();
    var psicologo = this.form.codigoPsicologo.value;
    if (!psicologo) {
      return;
    }
    this.nomePsicologoSelecionado = this.lstPsicologos.find((fun) => fun.codigoPsicologo === psicologo)?.nomePsicologo;
    await this.getAgendamentosPrevencao();
    this.spinnerService.hide();
  }

  public async mostrarEnvolvidos() {
    this.OnChangeAtividade();
    var tipoEnvolvido = this.formVisita.get('codigoAtividade')?.value;
    this.OnLimparEnvolvidos();
    this.formVisita.get('tipoGrupo')?.patchValue(null);
    if (tipoEnvolvido == 1) {
      //await this.getEnvolvidosPorChamado();
      this.mostrarAlunos = true;
      this.mostrarGrupos = false;
      this.mostrarProffisionais = false;
    } else if (tipoEnvolvido == 2) {
      this.mostrarAlunos = false;
      this.mostrarGrupos = true;
      this.mostrarProffisionais = false;
    } else if (tipoEnvolvido == 3) {
      this.mostrarAlunos = false;
      this.mostrarGrupos = false;
      this.mostrarProffisionais = true;
    } else {
      this.mostrarAlunos = false;
      this.mostrarGrupos = false;
      this.mostrarProffisionais = false;
    }
  }

  public async mostrarTipoGrupo() {
    var tipoGrupo = this.formVisita.get('tipoGrupo')?.value;
    this.OnLimparEnvolvidos();
    this.submittedModal = true;
    if (tipoGrupo == 1) {
      this.mostrarDivTurmas = true;
      this.caregarTurmas();
      this.mostrarDivEquipeEscolar = false;
      this.mostrarDivOutros = false;
    } else if (tipoGrupo == 2) {
      this.mostrarDivTurmas = false;
      this.mostrarDivEquipeEscolar = true;
      this.mostrarDivOutros = false;
    } else if (tipoGrupo == 3) {
      this.mostrarDivTurmas = false;
      this.mostrarDivEquipeEscolar = false;
      this.mostrarDivOutros = true;
    } else {
      this.mostrarDivTurmas = false;
      this.mostrarDivEquipeEscolar = false;
      this.mostrarDivOutros = false;
    }
  }

  public async GetFuncionariosPorCpf(tela: number) {
    const cpf = tela == 1 ? this.formModalEquipeEscolar.get('cpfFuncionario')?.value : this.formModalControls.cpfBusca.value;
    if (!this.validarCPF(cpf)) {
      this.toastr.warning('CPF invalido.');
      this.spinnerService.hide();
      return;
    }
    this.showDropAuxiliar = false;
    this.showDropFuncionarios = false;
    this.spinnerService.show();
    var getCPF = undefined;
    if (tela == 1) {
      getCPF = this.formModalEquipeEscolar.get('cpfFuncionario');
    }
    else {
      getCPF = this.formModalControls.cpfBusca;
    }
    const result = await lastValueFrom(await this.funcionarioService.getFuncionariosPorCpf(getCPF?.value.replace(".", "").replace("-", "")))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
    if (!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    if (result.data != null) {
      this.lstFuncionarios = result.data;
    }
    if (tela == 2) { this.showDropFuncionarios = true; } else { this.showDropAuxiliar = true; }
    if (this.lstFuncionarios.length == 0) {
      this.toastr.warning('Nenhum funcionario encontrado!');
    } else {
      if (this.lstFuncionarios.length == 1) {
        if (tela == 1) {
          this.formModalEquipeEscolar.get('cpfFuncionarioModalDrop')?.patchValue(this.lstFuncionarios[0].cpf);
        } else {
          this.formModalControls.cpfProfissionalSelecionado.patchValue(this.lstFuncionarios[0].cpf);
        }

      }
    }

    this.spinnerService.hide();
  }

  validarCPF(cpf: string): boolean {
    cpf = cpf.replace(/[^\d]/g, '');
    if (cpf.length !== 11) {
      return false;
    }
    if (/^(\d)\1+$/.test(cpf)) {
      return false;
    }
    let sum = 0;
    let remainder;
    for (let i = 1; i <= 9; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }
    if (remainder !== parseInt(cpf.substring(9, 10))) {
      return false;
    }
    sum = 0;
    for (let i = 1; i <= 10; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }
    return remainder === parseInt(cpf.substring(10, 11));
  }

  public FecharModalEquipeEscolar() {
    this.showModalEquipeEscolar = false;
  }

  public FecharModalOutros() {
    this.showModalOutros = false;
  }

  public AbrirModalEquipeEscolar() {
    this.submittedModal = false;
    this.formModalEquipeEscolar = new FormGroup({
      cpfFuncionario: new FormControl(null, [
        Validators.required,
        this.genericValidate.ValidaCpf,
      ]),
      cpfFuncionarioModalDrop: new FormControl(null, [Validators.required]),
    });
    this.showModalEquipeEscolar = true;
  }

  public AbrirModalOutros() {
    this.submittedModal = false;
    this.formModalOutros = new FormGroup({
      dsOutros: new FormControl(null, [Validators.required]),
    });
    this.showModalOutros = true;
  }

  public OnAdicionarEquipeEscolar() {
    if (this.formModalEquipeEscolar.invalid) {
      return;
    }

    const objetoEnvolvido: Envolvido = {
      tipoPessoa: 2,
      tipoEnvolvido: 0,
      cpf: this.formModalEquipeEscolar.get('cpfFuncionarioModalDrop')?.value,
    };

    const nomeFuncionario = this.lstFuncionarios.find((fun) => fun.cpf === objetoEnvolvido.cpf)?.nome
    const nomeFuncSplit = nomeFuncionario?.split(' ');

    if (nomeFuncSplit != undefined) {
      const iniciais = nomeFuncSplit
        .filter(parte => parte.length > 3)
        .map(parte => parte.charAt(0))
        .slice(0, 2)
        .join('');

      objetoEnvolvido.iniciais = iniciais;
    }

    if (nomeFuncSplit != null)
      objetoEnvolvido.nome = nomeFuncionario;

    objetoEnvolvido.idTemporario = this.envolvidos.reduce((maxId, envolvido) => Math.max(maxId, envolvido.idTemporario || 0), 0) + 1;
    this.envolvidos.push(objetoEnvolvido);
    this.lstEnvolvidosSelecionados.push(objetoEnvolvido);

    this.toastr.success(`Equipo Escolar inserido com sucesso`);
    this.FecharModalEquipeEscolar();
  }

  public OnAdicionarOutros() {
    const objetoEnvolvido: Envolvido = {
      tipoPessoa: 3,
      tipoEnvolvido: 0,
      dsOutros: this.formModalOutros.get('dsOutros')?.value,
      nome: this.formModalOutros.get('dsOutros')?.value,
    };

    const nomeFuncSplit = objetoEnvolvido.nome?.split(' ');

    if (nomeFuncSplit != undefined) {
      const iniciais = nomeFuncSplit
        .filter(parte => parte.length > 3)
        .map(parte => parte.charAt(0))
        .slice(0, 2)
        .join('');

      objetoEnvolvido.iniciais = iniciais;
    }

    objetoEnvolvido.idTemporario = this.envolvidos.reduce((maxId, envolvido) => Math.max(maxId, envolvido.idTemporario || 0), 0) + 1;
    this.envolvidos.push(objetoEnvolvido);
    this.lstEnvolvidosSelecionados.push(objetoEnvolvido);
    this.toastr.success(`Outros inserido com sucesso`);
    this.FecharModalOutros();
  }

  public OnExcluirEnvolvido(IdTemporario: number) {
    this.showModalExcluirEnvolvido = true;
    this.envolvidoExclusao = this.envolvidos.filter(
      (envolvido) => envolvido.idTemporario === IdTemporario
    )[0];
    this.descricaoExclusao = `Deseja remover o Envolvido ${this.envolvidoExclusao.nome == undefined ? "Outros" : this.envolvidoExclusao.nome} ?`;
  }

  public FecharModalExclusao() {
    this.showModalExcluirEnvolvido = false;
    this.descricaoExclusao = "";
    this.envolvidoExclusao = undefined;
  }

  public ExluirEnvolvido(IdTemporario?: number) {
    const indiceParaRemover = this.envolvidos.findIndex(
      (envolvido) => envolvido.idTemporario === IdTemporario
    );
    this.envolvidos.splice(indiceParaRemover, 1);
    this.toastr.success(
      'Envolvido removido com sucesso!'
    );
    this.FecharModalExclusao();
  }

  public setNovoPasso(novoPasso: number) {
    this.spinnerService.show();
    var pasoAValidar = novoPasso - 1 - (this.temDeslocamento ? 1 : 0);
    if (!this.ValidaCampos(pasoAValidar)) {
      this.spinnerService.hide();
      return;
    }

    if (novoPasso == 3 || (this.temDeslocamento && novoPasso == 4)) {
      this.objetivoAcao = this.formVisita.get('objetivoAcao')?.value;
      this.avaliacaoResultado = this.formVisita.get('avaliacaoResultado')?.value;
      this.impactoAcao = this.formVisita.get('impactoAcao')?.value;
    }

    this.Passo = novoPasso;
    this.spinnerService.hide();
  }

  public proximoPassoDeslocamento(modelDeslocamento: AtividadePsicologoDeslocamento) {
    this.modelDeslocamento = modelDeslocamento;
    this.Passo = 2; // Proximo passo do acolhimento
  }

  public ValidaPassoAtual(passo: number): boolean {
    return this.Passo == passo;
  }

  addEnvolvidoOnLstEnvolvidoSelecionado(codigoEnvolvido: number, target: EventTarget) {
    const checkbox = target as HTMLInputElement;
    if (checkbox.checked)
      this.lstEnvolvidosSelecionados.push(this.lstEnvolvidos.find(x => x.codigoEnvolvido == codigoEnvolvido)!);
    else
      this.lstEnvolvidosSelecionados = this.lstEnvolvidosSelecionados.filter(x => x.codigoEnvolvido != codigoEnvolvido);
  }

  IsEnvolvidoSelected(envolvido: Envolvido): boolean {
    return this.lstEnvolvidosSelecionados.includes(envolvido);
  }

  public OnChangeData() {
    this.dataVisita = this.formVisita.get('dataVisita')?.value;
  }

  public OnChangeAtividade() {
    var atividade = this.formVisita.get('codigoAtividade');
    this.atividadeRealizadaSelecionado = this.lstAtividadeRealizada.find((fun) => fun.id === atividade?.value)?.name;
  }

  public changeInputRange() {
    const inputRange = document.getElementById('input-range') as HTMLFormElement;
    this.duracaoVisita = inputRange.value;
  }

  OnAdicionarProfissionaisEOutros() {
    const tipoPessoa = this.formModalControls.tipoPessoa.value;
    const dsOutros = this.formModalControls.dsOutros.value;
    const cpfSelecionado = this.formModalControls.cpfProfissionalSelecionado.value;
    if (tipoPessoa == 1 && dsOutros) {
      this.lstOutros.push(dsOutros);
    } else if (tipoPessoa == 2 && cpfSelecionado) {
      var funcionario = this.lstFuncionarios.find(x => x.cpf == cpfSelecionado);
      if (funcionario) {
        this.lstFuncionariosSelecionados.push(funcionario);
      }
    } else {
      this.toastr.error('Preencha todos os campos.');
    }
    this.lstFuncionarios = [];
    this.FecharModal();
    this.formModalControls.tipoPessoa.setValue(null);
    this.formModalControls.cpfBusca.setValue(null);
    this.formModalControls.dsOutros.setValue(null);
  }

  removeFuncionario(codigoFuncionario: number) {
    this.lstFuncionariosSelecionados = this.lstFuncionariosSelecionados.filter(x => x.codigoFuncionario != codigoFuncionario);
  }

  removeOutro(outro: string) {
    this.lstOutros = this.lstOutros.filter(x => x != outro);
  }

  removeAluno(codigoAluno: number) {
    this.alunosSelecionados = this.alunosSelecionados.filter(x => x.codigoAluno != codigoAluno)
  }

  AbreModalProfissionais() {
    this.showModal = true;
  }

  FecharModal() {
    this.showModal = false;
    this.showDropFuncionarios = false;
    this.formModalControls.cpfBusca.setValue(null);
    this.formModalControls.dsOutros.setValue(null);
  }

  changeTipoPessoaModal() {
    this.showDropFuncionarios = false;
    this.lstFuncionarios = [];
  }

  AbreModalAluno() {
    this.showModalAluno = true;
  }

  FecharModalAluno() {
    this.showModalAluno = false;
    this.resetModalEnvolvidos();
  }

  resetModalEnvolvidos() {
    this.formModalAluno.reset();

  }

  OnAdicionarEnvolvido() {
    if (this.formModalAluno.invalid) {
      this.toastr.warning("Selecione um aluno para salvar na atividade.");
      return;
    }

    var alunoSelecionado = this.alunos.find(x => x.ra == this.modalFormAluno.raAlunoModalDrop.value)!;
    this.alunosSelecionados.push(alunoSelecionado);
    this.showDropAuxiliar = false;
    this.showModalAluno = false;
    this.resetModalEnvolvidos();
  }

  public BuscarAlunoPorNomeRA() {
    const regexNumeros = /\d$/;
    const regexLetras = /^[a-zA-Z]+$/;
    const regexAmbos = /^[a-zA-Z0-9]+$/;

    this.modalFormAluno.raAlunoModalDrop.patchValue(null);
    this.modalFormAluno.raAlunoModalDrop.removeValidators([Validators.required]);
    this.validaBuscaRA = false;
    let nomeRa = this.modalFormAluno.raNomeAluno.value;
    let buscarPorRa = false;

    if (regexNumeros.test(nomeRa)) {
      this.modalFormAluno.codigoDiretoriaModal.clearAsyncValidators();
      this.modalFormAluno.codigoEscolaModal.clearAsyncValidators();
      buscarPorRa = true;
    }
    else if (regexLetras.test(nomeRa)) {
      this.validaBuscaRA = true;
      this.modalFormAluno.codigoDiretoriaModal.addValidators([Validators.required]);
      this.modalFormAluno.codigoEscolaModal.addValidators([Validators.required]);
    }
    else if (regexAmbos.test(nomeRa)) {
      this.validaBuscaRA = true;
      this.modalFormAluno.raNomeAluno.setValue(nomeRa.replace(/\d/g, ''));
    }
    else {
    }
    this.AtualizarValidacoesAlunos();

    if (this.modalFormAluno.invalid) {
      this.toastr.warning("Deverá ser selecionado o aluno ofendido/ofensor");
      return;
    }
    if (buscarPorRa) {
      this.getAlunosPorRa();
    } else {
      this.getAlunosPorNome();
    }
    this.modalFormAluno.raAlunoModalDrop.addValidators([Validators.required]);
    this.AtualizarValidacoesAlunos();
  }

  public AtualizarValidacoesAlunos() {
    this.modalFormAluno.codigoDiretoriaModal.updateValueAndValidity();
    this.modalFormAluno.codigoEscolaModal.updateValueAndValidity();
    this.modalFormAluno.raNomeAluno.updateValueAndValidity();
    this.modalFormAluno.raAlunoModalDrop.updateValueAndValidity();
  }

  public async getAlunosPorRa() {
    this.spinnerService.show();
    this.showDropAuxiliar = false;
    const raAluno = this.formModal.get('raNomeAluno');
    const result = await lastValueFrom(this.alunoService.getAlunosPorRa(raAluno?.value))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
    if (!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.RetornoBuscaAlunoPorRA(result);
    this.spinnerService.hide();
  }

  private RetornoBuscaAlunoPorRA(response: DefaultResponse) {
    if (response.data != null) {
      this.aluno = response.data;
      this.modalFormAluno.raAlunoModalDrop.patchValue(response.data.ra);
      this.alunos.push(this.aluno);
      this.showDropAuxiliar = true;
      this.toastr.success('Aluno carregado com sucesso.');
      return;
    }
    this.toastr.warning('Nenhum aluno encontrado!');
  }

  public async getAlunosPorNome() {
    const nmAluno = this.modalFormAluno.raNomeAluno.value;
    const codigoDiretoria = this.modalFormAluno.codigoDiretoriaModal.value;
    const codigoEscola = this.modalFormAluno.codigoEscolaModal.value;
    this.spinnerService.show();
    this.showDropAuxiliar = false;
    const result = await lastValueFrom(this.alunoService.getAlunosPorNome(nmAluno, codigoDiretoria, codigoEscola))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
    if (!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.RetornoBuscaAlunos(result);
    this.spinnerService.hide();
  }

  private RetornoBuscaAlunos(response: DefaultResponse) {
    if (response.data != null) {
      this.alunos = response.data;
    }
    if (this.alunos.length == 0) {
      this.toastr.warning('Nenhum aluno encontrado!');
    }
    if (this.alunos.length == 1) {
      this.formModal.get('raAlunoModalDrop')?.patchValue(this.alunos[0].ra);
    }
    if (this.alunos.length > 1) {
      this.toastr.success('Alunos carregados com sucesso. Por favor selecione um.');
    }
    this.showDropAuxiliar = true;
  }

  public async getEscolasModalEnvolvidos() {
    this.spinnerService.show();
    this.zerarEscolaModal();

    const codigoDE = this.modalFormAluno.codigoDiretoriaModal.value;
    if (codigoDE) {
      const result = await lastValueFrom(this.escolaService.getEscolasPorCodigoDiretoria(codigoDE))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
      if (!result.isSucess) {
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }

      this.escolasModalAlunos = result.data;
      this.modalFormAluno.codigoEscolaModal.enable();
      this.spinnerService.hide();
    } else {
      this.escolasModalAlunos = [];
      this.spinnerService.hide();
    }
  }

  zerarEscolaModal() {
    this.escolasModalAlunos = [];
  }

  public setVoltarPasso(PassoAtual: number) {
    if (PassoAtual - 1 > 0) {
      this.Passo = PassoAtual - 1;
    }
    else {
      this.toastr.info('Não pode voltar da primeira pagina ...');
    }
  }

  OnChangeCountLetters(campo: number) {
    const avalia = this.formVisita.get('avaliacaoResultado')!.value;
    const impacto = this.formVisita.get('impactoAcao')!.value;
    if (avalia != null && campo == 1)
      this.qtdeCaracteresAvalia = avalia.length;
    if (impacto != null && campo == 2)
      this.qtdeCaracteresImpacto = impacto.length;
  }

  async getAgendamentosPrevencao() {
    this.spinnerService.show();


    var result = await lastValueFrom(this.psicologoService
      .getAgendamentosPrevencao(this.form.codigoEscola.value,
        this.form.codigoDiretoria.value, this.form.codigoPsicologo.value));
    if (!result.isSucess) {
      this.toastr.error(result.message);
      this.spinnerService.hide();
      return;
    }
    this.lstAgendamentosPrevencao = result.data;

    this.spinnerService.hide();

  }

  toggleAgendamento(checked: boolean, agendamento: AgendamentoPrevencaoDto) {
    if (checked) {
      this.agendamentoSelecionado = agendamento;
      this.SetEnvolvidoAgendamento();
      this.form.codigoAtividade.setValue(2);
      this.form.codigoAtividade.disable();
    } else {
      this.agendamentoSelecionado = undefined;
      this.RemoveEnvolvidosAgendamento();
      this.form.codigoAtividade.setValue(null);
      this.form.codigoAtividade.enable();
    }
    console.log(this.agendamentoSelecionado)

  }

  SetEnvolvidoAgendamento(){


    console.log(this.agendamentoSelecionado?.agendamentoEnvolvidos)
    if(this.agendamentoSelecionado?.pessoa == 4){
      this.form.tipoGrupo.setValue(1);
      const envolvidoTurma:Envolvido[] = this.agendamentoSelecionado
      .agendamentoEnvolvidos
      .map((arrayItem:AgendamentoPrevencaoEnvolvidoDto)=>{

        const objetoEnvolvido: Envolvido = {
          tipoPessoa: 4,
          tipoEnvolvido: 0,
          nome: arrayItem.descricaoTurma,
          codigoTurma: arrayItem.codigoTurma,
          numeroClasse: arrayItem?.numeroClasse,
          nomeTipoEnsino: arrayItem?.nomeTipoEnsino,
          numeroSerie: arrayItem?.numeroSerie,
          descricaoTurma: arrayItem?.descricaoTurma
        };

        return objetoEnvolvido;
      });
      const turmas:Turma[] = this.agendamentoSelecionado
      .agendamentoEnvolvidos
      .map((arrayItem:AgendamentoPrevencaoEnvolvidoDto)=>{

        const objetoEnvolvido: Turma = {
          codigoTurma: arrayItem.codigoTurma!,
          numeroClasse: arrayItem.numeroClasse!,
          nomeTipoEnsino: arrayItem.nomeTipoEnsino!,
          numeroSerie: arrayItem.numeroSerie!,
          descricaoTurma: arrayItem.descricaoTurma!,
          anoLetivo:'',
          identificadorTurma:'',
          codigoUnidade:0,
          numeroSala:'',
          codigoEscola:0,
          codigoTurno:0,
          tipoRedeEnsino:0,
          codigoTipoEnsino:0,
          codigoTipoClasse:0,
        };

        return objetoEnvolvido;
      });


      this.lstEnvolvidosSelecionados = envolvidoTurma;
      this.lstTurmasSelecionadas = turmas ;
    }else if(this.agendamentoSelecionado?.pessoa == 3){
      this.form.tipoGrupo.setValue(3);
      const outros:Envolvido[] = this.agendamentoSelecionado
      .agendamentoEnvolvidos
      .map((arrayItem:AgendamentoPrevencaoEnvolvidoDto)=>{
        const objetoEnvolvido: Envolvido = {
          tipoPessoa: 3,
          tipoEnvolvido: 0,
          dsOutros: arrayItem.outros,
          nome: arrayItem.outros,
          iniciais:this.getIniciais(arrayItem.outros!)
        };
      return objetoEnvolvido;
      });
      this.lstEnvolvidosSelecionados = outros;
      this.lstOutros = outros.map(x=>x.dsOutros!);
    }else if(this.agendamentoSelecionado?.pessoa == 2){
      this.form.tipoGrupo.setValue(2);
      const funcionariosEnvolvidos:Envolvido[] =  this.agendamentoSelecionado
      .agendamentoEnvolvidos
      .map((arrayItem:AgendamentoPrevencaoEnvolvidoDto)=>{
        const funcionario:Envolvido ={
          tipoPessoa: 2,
          tipoEnvolvido: 0,
          cpf: arrayItem.cpf!,
          nome: arrayItem.nomeEquipeEscolar!,
          iniciais:this.getIniciais(arrayItem.nomeEquipeEscolar!)
        }
        return funcionario;
      });

      const funcionarios:Funcionario[] =  this.agendamentoSelecionado
      .agendamentoEnvolvidos
      .map((arrayItem:AgendamentoPrevencaoEnvolvidoDto)=>{
        const funcionario:Funcionario ={
          cpf: arrayItem.cpf!,
          nome: arrayItem.nomeEquipeEscolar!,
          email:'',
          rg:'',
          codigoFuncionario:0
        }
        return funcionario;
      });

      this.lstEnvolvidosSelecionados = funcionariosEnvolvidos;
      this.lstFuncionariosSelecionados = funcionarios;

    }
  }

  getIniciais(nomeCompleto:string){
    const nomeCompletoSplit = nomeCompleto?.split(' ');

    if (nomeCompletoSplit) {
      const iniciais = nomeCompletoSplit
        .filter(parte => parte.length > 3)
        .map(parte => parte.charAt(0))
        .slice(0, 2)
        .join('');

      return iniciais;
    }
    return '';
  }

  RemoveEnvolvidosAgendamento(){
    this.lstTurmasSelecionadas = [];
    this.lstEnvolvidosSelecionados = [];
    this.lstFuncionariosSelecionados = [];
  }
}
