import { Location, formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataTableDirective } from "angular-datatables";
import { ADTSettings } from "angular-datatables/src/models/settings";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subject, lastValueFrom } from "rxjs";
import { AuthService } from 'src/app/shared/auth/services/auth.service';
import { Envolvido } from 'src/app/shared/models/chamados/envolvido';
import { TurmasPOC } from 'src/app/shared/models/professor/TurmasAprovacaoPOC';
import { SetUpdateAtividadePsicologoCommand } from 'src/app/shared/models/psicologo/UpdateArquivosPsicologo';
import { PsicologoAtividade } from 'src/app/shared/models/psicologo/ValidarAtividadePsicologo';
import { AtribuicaoLoginSed, UserLoginSed } from 'src/app/shared/models/sed/UserLogin';
import { ArquivoService } from 'src/app/shared/services/arquivo.service';
import { ConcluirService } from 'src/app/shared/services/concluir.service';
import { ConvivaErrorHandlingService } from 'src/app/shared/services/convivaErrorHandlingService';
import { DetalheService } from 'src/app/shared/services/detalhe.service';
import { EnvolvidoService } from 'src/app/shared/services/envolvido.service';
import { PsicologoService } from 'src/app/shared/services/psicologo.service';

@Component({
  selector: 'app-visualizar-atividade',
  templateUrl: './visualizar-atividade.component.html',
  styleUrls: ['./visualizar-atividade.component.scss']
})
export class VisualizarAtividadeComponent implements OnInit {
    public userLogado: UserLoginSed | null;
    public atribuicoesLogin: AtribuicaoLoginSed;
    isChecked = false;
    public dtOptions: any = {};
    public dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();
    @ViewChild(DataTableDirective, {static: false})
    public dtElement: DataTableDirective;
    public hideAfterLoadTable: boolean;
    public hideAfterLoadTableAlunos: boolean;
    public isDtInitialized:boolean = false;
    public parametroId: any;
    public chamadoEncerrar: number | null = null;
    public checkbox1: boolean = false;
    public checkbox2: boolean = false;
    public checkbox3: boolean = false;
    public showModal: boolean = false;
    public tpAcaoAtividade: string = "";

    constructor(private spinnerService: NgxSpinnerService,
      private detalheService: DetalheService,
      private ConvivaErrors: ConvivaErrorHandlingService,
      private toastr: ToastrService,
      @Inject(LOCALE_ID) private locale: string,
      private envolvidoService: EnvolvidoService,
      private concluirService: ConcluirService,
      private psicologoService: PsicologoService,
      private arquivoService: ArquivoService,
      private authService: AuthService,
      private route: ActivatedRoute,
      private location: Location
     ) {
      this.userLogado = this.authService.getUser();

      if(this.userLogado != null){
        this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];

        this.route.paramMap.subscribe((params) => {
          this.parametroId = Number(params.get('id')) ?? 0;
        });


      }
    }
    //Forms da tela
    public isButtonVisible: boolean = true;
    public submitted: boolean;

    //Modal
    public showModalConfirmacao:boolean;

    //Listas da tela
    public atividade: PsicologoAtividade = new PsicologoAtividade();
    public lstEnvolvidos: Envolvido[] = [];
    public lstTurmas: TurmasPOC[] = [];
    public lstEquipeEscolar: string[] = [];
    public lstOutros: string[] = [];

    // FORM CADASTRAR
    public formCadastrar!: FormGroup;

    public get form() {
      return this.formCadastrar.controls;
    }

    //BTN VOLTAR
    goBack(): void {
      this.location.back();
    }

    public async ngOnInit() {

      this.dtOptions = {
        deferRender: true,
        destroy:true,
        processing: true,
        paging: true,
        dom: 'Bfrtip',
        order: [
          [0, 'asc'],
          [6, 'asc']
        ],
        language: {
          url: 'https://cdn.datatables.net/plug-ins/1.11.5/i18n/pt-BR.json'
        },
        lengthMenu: [
          [ 10, 25, 50, -1 ],
          [ '10 linhas', '25 linhas', '50 linhas', 'Tudo' ]
        ],
        buttons: [
          'pageLength',
          {
            extend:'print',
            exportOptions:{
              columns:[0,1,2,3,4,5,6]
            }
          },
          {
            extend:'excel',
            exportOptions:{
              columns:[0,1,2,3,4,5,6]
            }
          },
          {
            extend:'pdf',
            exportOptions:{
              columns:[0,1,2,3,4,5,6]
            }
          }
        ],
      };
      this.formCadastrar = new FormGroup({
        dsAprovar: new FormControl('')
      });
      await this.PopularDados();
    }


    public async PopularDados() {
      this.spinnerService.show();
      const result = await lastValueFrom(await this.detalheService.getAtividadeDetalhePsicologoPorId(this.parametroId))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });

      if(!result.isSucess){
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }
      this.atividade = result.data

      if(this.atividade.tP_ACAO_ATIVIDADE == 1 || this.atividade.tP_ACAO_ATIVIDADE == 3){
        this.tpAcaoAtividade = " Prevenção";
      }else
        this.tpAcaoAtividade = " Acolhimento";

      if (this.atividade.tP_ACAO_ATIVIDADE == 5) {
          this.tpAcaoAtividade = "Outros";
      }

      if(this.atividade.tP_ATIVIDADE_REALIZADA == 1){
        var envolvido = await lastValueFrom(
          this.envolvidoService.GetEnvolvidosPorChamado(this.atividade.cD_CHAMADO!));
          this.lstEnvolvidos = envolvido.data;
      }
      console.log(this.atividade.atividadeEnvolvidos)
      if(this.atividade.tP_ATIVIDADE_REALIZADA == 2){
        const tipoPessoa = this.atividade.atividadeEnvolvidos[0].tipoPessoa;
        if(tipoPessoa == 4){
          // var turmas = await lastValueFrom(this.psicologoService
          //   .getTurmasAprovacaoPsicologo(this.atividade.cD_ATIVIDADE_PSICOLOGO));
          const envolvidosTurmas = this.atividade.atividadeEnvolvidos.map(x=>{
            const turma:TurmasPOC = {
              cD_TURMA: x.codigoTurma!,
              dS_TURMA: x.descricaoTurma!,
              nM_TIPO_ENSINO: x.nomeTipoEnsino!,
              nR_CLASSE: String(x.numeroClasse!),
              nR_SERIE: x.numeroSerie!
            }
            return turma;
          });

          this.lstTurmas = envolvidosTurmas;
        }else if(tipoPessoa == 2){
          this.lstEquipeEscolar = this.atividade.atividadeEnvolvidos.map(x=>x.nomeEquipeEscolar!);
        }else if(tipoPessoa == 3){
          this.lstOutros = this.atividade.atividadeEnvolvidos.map(x=>x.outros!);
        }


      }

         setTimeout(() => {
           this.spinnerService.hide();
         }, 3000);
  }
    public getDate(data?: Date) {
      if (data == undefined) return;
    return formatDate(data, 'dd/MM/yyyy', this.locale);
  }
    public getTempoDeslocamento(saida?: string, chegada?: string): string {
    if (!saida || !chegada) return '';

    const [horaSaida, minutoSaida] = saida.split(':').map(Number);
    const [horaChegada, minutoChegada] = chegada.split(':').map(Number);

    const dataSaida = new Date();
    dataSaida.setHours(horaSaida, minutoSaida);

    const dataChegada = new Date();
    dataChegada.setHours(horaChegada, minutoChegada);

    const diferencaMs = dataChegada.getTime() - dataSaida.getTime();
    const diferencaMin = Math.floor(diferencaMs / 60000);
    const horas = Math.floor(diferencaMin / 60);
    const minutos = diferencaMin % 60;

    return `${this.pad(horas)}:${this.pad(minutos)}`;
  }

  pad(num: number): string {
    return num.toString().padStart(2, '0');
    }

    public abrirEmNovaAba(caminho:string): void {
      if (this.atividade && caminho) {
        window.open(caminho, '_blank');
      }
    }
    public getArquivoNomeCompleto(): string {
      if (!this.atividade || !this.atividade.nM_ARQUIVO) {
        return '';
      }
      return this.atividade.nM_ARQUIVO.slice(37);
    }


        getShortenedName(name: string): string {
          if (!name || name.length <= 19) {
            return name;
          }

          const firstPart = name.slice(0, 15);
          const lastPart = name.slice(-4);
          return `${firstPart}${lastPart}`;
        }

        public removeFile(caminhoAzure: string): void {
          if (!this.atividade.arquivos) return;
          const index = this.atividade.arquivos.findIndex(
            (arquivo) => arquivo.caminhoAzure === caminhoAzure
          );
          if (index !== -1) {
            this.atividade.arquivos.splice(index, 1);
          }
        }

  public async OnFileChange(event: any) {
    const maxFileSize =  31 * 1024 * 1024; // 30 MB
    if (event.target.files.length > 0) {
      this.spinnerService.show();
      const formData = new FormData();
      const files = event.target.files;
      const permittedTypes: string[] = [
        "image/jpg",
        "image/jpeg",
        "image/png",
        "application/pdf",
        "application/ppt",
        "application/pptx",
        "application/potx",
        "application/ppsx",
        "application/odp",
        "video/mp4",
        "audio/mpeg"
      ]
      for (const file of files) {
        if (file.size > maxFileSize) {
          this.toastr.warning('O tamanho máximo do arquivo é 30 MB.');
          const fileInput = document.getElementById('arquivos') as HTMLInputElement;
          if (fileInput) {
              fileInput.value = '';
          }
          this.spinnerService.hide();
          return;
        }

        this.getShortenedName(file.name);
        var qtdeCaracteresNomes = file.name.length;
        if (permittedTypes.includes(file.type) && qtdeCaracteresNomes <= 80) {
          formData.append(file.name, file);
        }
        else {
          if(qtdeCaracteresNomes > 80){
            this.toastr.warning('O nome do arquivo excede a quantidade de caracteres permitida(max:80).');

          }else{
            this.toastr.warning('Só é possivel subir arquivos .jpg, .jpeg , .png, .pdf, .mp3 , .mp4, .ppt, .pptx, .potx e ppsx');
          }


          const fileInput = document.getElementById('arquivos') as HTMLInputElement;
          if (fileInput) {
            fileInput.value = '';
          }
          this.spinnerService.hide();
          return;
        }
      }

      const result = await lastValueFrom(await this.arquivoService.setArquivoBase64(formData))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });

      if (!result.isSucess) {
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      } else {
        this.atividade.arquivos?.push(...result.data);
        event.target.value = '';
      }
      this.spinnerService.hide();
    }
  }

  public async Salvar() {
    if(this.atividade.arquivos!.length == 0){
      this.showModal = true;
      return;
    }
    this.spinnerService.show();
    const parametros: SetUpdateAtividadePsicologoCommand = {
      arquivos: this.atividade.arquivos,
      cd_atividade_psicologo: this.atividade.cD_ATIVIDADE_PSICOLOGO
    };

    try {
      const result = await lastValueFrom(this.psicologoService.setUpdateAtividadePsicologo(parametros));

      if (!result.isSucess) {
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }

      this.toastr.success('Atividade atualizada com sucesso!');
      this.spinnerService.hide();
    } catch (err) {
      this.ConvivaErrors.handleResultError(err);
      this.spinnerService.hide();
    }
  }


  closeModal(){
    this.showModal = false;
  }

  }
