import { ArquivoAzure } from "../arquivo/ArquivoAzure";
import { Envolvido } from "../chamados/envolvido";

export interface AtividadePsicologo{
    codigoAtividadePsicologo?: number;
    codigoPolo: number;
    codigoDiretoria: number;
    nomeDiretoria: string;
    codigoEscola: number;
    nomeEscola: string;
    codigoPsicologo?: number;
    codigoChamado?: number;
    dataAtividadePsicologo: Date;
    timeAtividadePsicologo: string;
    tipoAcaoAtividade: number;
    tipoAtividadeRealizada: number;
    descricaoRespostaPerguntaObjetivoAcao: string;
    descricaoRespostaPerguntaAvaliacaoResultadoAcao: string;
    descricaoRespostaPerguntaImpactoAcaoRealizada: string;
    descricaoAvaliacaoResultadoAcao?: string;

    StatusAprovacaoDiretoria?: number;
    observacaoAprovacaoDiretoria?: string;
    descricaoResultadoAvaliacaoDiretoria?: string;
    codigoUsuarioAprovacaoDiretoria?:number;
    codigoPerfilAprovacaoDiretoria?: number;
    nomePerfilAprovacaoDiretoria?: string;
    dataAprocacaoDiretoria?: Date;

    statusAprovacaoFiscal?: number;
    observacaoAprovacaoFiscal?: string;
    descricaoResultadoAvaliacaoFiscal?: string;
    codigoUsuarioAprovacaoFiscal?:number;
    codigoPerfilAprovacaoFiscal?: number;
    nomePerfilAprovacaoFiscal?: string;
    dataAprocacaoFiscal?: Date;

    codigoDeslocamentoDiretoriaOrigem?: number;
    nomeDeslocamentoDiretoriaOrigem?: string;
    codigoDeslocamentoEscolaOrigem?: number;
    nomeDeslocamentoEscolaOrigem?: string;
    codigoDeslocamentoDiretoriaDestino?: number;
    nomeDeslocamentoDiretoriaDestino?: string;
    codigoDeslocamentoEscolaDestino?: number;
    nomeDeslocamentoEscolaDestino?: string;
    codigoTipoTransporte?: number;
    horaDeslocamentoChegada?: string;
    horaDeslocamentoSaida?: string;

    codigoAgendamento?: number;
    tipoAcao: number;
    codigoPerfil?: number;
    nomePerfil?: string;
    codigoUsuario?: number;
    arquivos?: ArquivoAzure[];
    listaEnvolvidos?: Envolvido[];

}

export class Atividade {
    codigoPolo? : number;
    codigoDiretoria?: number;
    codigoEscola?: number;
    dataInicio?: Date;
    dataFim?: Date;
}
