import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './pages/404/404.component';
import { AgendamentoPsicologosComponent } from './pages/agendamento-psicologos/agendamento-psicologos.component';
import { AtendimentoPsicologosComponent } from './pages/atendimento-psicologos/atendimento.psicologo.component';
import { BuscaComponent } from './pages/busca/busca.component';
import { AdicionarChamadoComponent } from './pages/chamado/adicionar/chamado-add.component';
import { ChamadosListComponent } from './pages/chamado/chamados-list/chamados-list.component';
import { DetalheComponent } from './pages/chamado/detalhe/detalhe.component';
import { EditarChamadoComponent } from './pages/chamado/editar/chamado-edit.component';
import { ChamadoComponent } from './pages/chamado/listar/chamado.component';
import { DashboardNaoOcorrenciaComponent } from './pages/dashboard-nao-ocorrencia/dashboard-nao-ocorrencia.component';
import { DashboardReincidenciaComponent } from './pages/dashboard-reincidencia/dashboard-reincidencia.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AprocacaoVisitaFiscalComponent } from './pages/fiscal/visitas/aprovacao/aprovacao-visita-fiscal.component';
import { AvailacaoVisitaFiscalComponent } from './pages/fiscal/visitas/avaliacao/availacao-visita-fiscal.component';
import { DashboardVisitasComponent } from './pages/fiscal/visitas/dashboard/dashboard-visitas.component';
import { VisualizarAtividadePsicologoFiscalComponent } from './pages/fiscal/visitas/visualizar/visualizar-atividade.component';
import { FuncionalidadeManageComponent } from './pages/funcionalidade-manage/funcionalidade-manage.component';
import { PageGerenciamentoEmailComponent } from './pages/gerenciamento-email/gerenciamento-email.component';
import { HomeComponent } from './pages/home/home.component';
import { GerenciarLoginComponent } from './pages/login/gerenciar-login/gerenciar-login.component';
import { LoginComponent } from './pages/login/login.component';
import { LogoutComponent } from './pages/login/logout/logout.component';
import { MainComponent } from './pages/main/main.component';
import { AddNaoOcorrenciaComponent } from './pages/nao-ocorrencia/adicionar/nao-ocorrencia-add.component';
import { NotificacoesComponent } from './pages/notificacoes/notificacoes.component';
import { PerfilAlunoComponent } from './pages/perfil-aluno/perfil-aluno.component';
import { PerfilManageComponent } from './pages/perfil-manage/perfil-manage.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { PageVisitaProfessorAcolhimentoComponent } from './pages/professor-poc/visitas/acolhimento/professor-visita-acolhemento.component';
import { PageAprocacaoPocVisitasComponent } from './pages/professor-poc/visitas/aprovacao/aprovacao-visitas.component';
import { PageDashboardPocVisitasComponent } from './pages/professor-poc/visitas/dashboard/dashboard-visitas.component';
import { PageVisitaProfessorPrevencaoComponent } from './pages/professor-poc/visitas/prevencao/professor-visita-prevencao.component';
import { PageFiltroVisitasProfessorComponent } from './pages/professor-poc/visitas/professor-filtro-visitas.component';
import { VizualizarAtividadePocComponent } from './pages/professor-poc/visitas/vizualizar/vizualizar-atividade-poc';
import { AdicionarPsicologoComponent } from './pages/psicologo/adicionar/psicologo-add.component';
import { EditarPsicologoComponent } from './pages/psicologo/editar/psicologo-edit.component';
import { ManutencaoPsicologoComponent } from './pages/psicologo/manutencao/psicologo-manutencao.component';
import { VisitaEditarComponent } from './pages/psicologo/visita-editar/visita-editar.component';
import { VisitaPsicologoComponent } from './pages/psicologo/visita/psicologo-visita.component';
import { PageVisitaPsicologoacolhimentoComponent } from './pages/psicologo/visitas/acolhimento/psicologo-visita.acolhimento';
import { AgendamentoPrevencaoComponent } from './pages/psicologo/visitas/agendamento-prevencao/agendamento-prevencao.component';
import { PageAprocacaoVisitasComponent } from './pages/psicologo/visitas/aprovacao/aprovacao-visitas.component';
import { PageDashboardVisitasComponent } from './pages/psicologo/visitas/dashboard/dashboard-visitas.component';
import { PageVisitaPsicologoOutrosComponent } from './pages/psicologo/visitas/outros/psicologo-visita-outros.component';
import { PageVisitaPsicologoPrevencaoComponent } from './pages/psicologo/visitas/prevencao/psicologo-visitas-prevencao.component';
import { PageFiltroVisitasPsicologoComponent } from './pages/psicologo/visitas/psicologo-filtro-visitas.component';
import { VisualizarAtividadeComponent } from './pages/psicologo/visitas/visualizar/visualizar-atividade.component';
import { ConsolidadoComponent } from "./pages/relatorio/consolidado/consolidado.component";
import { AuthGuardService } from './shared/guards/auth-guard.service';

const routes: Routes = [
  {
    path:'',
    component: MainComponent,
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    children:[
      {
        path:'',
        component: HomeComponent
      },
      {
        path:'buscar-alunos',
        component: BuscaComponent
      },
      {
        path: 'listar-chamados',
        component: ChamadoComponent,
      },
      {
        path: 'listar-chamados/:ic/:ie',
        component: ChamadoComponent,
      },
      {
        path: 'listar-chamados/:ic/:ie/:di/:es/:an',
        component: ChamadoComponent,
      },  
      {
        path: 'chamados-list',
        component: ChamadosListComponent,
      },
      {
        path: 'cadastrar-chamados/:ra/:te',
        component: AdicionarChamadoComponent,
      },
      {
        path: 'cadastrar-chamados',
        component: AdicionarChamadoComponent
      },
      {
        path: 'agendamento-visita-prevencao',
        component: AgendamentoPrevencaoComponent
      },
      {
        path: 'cadastrar-chamados/:dt',
        component: AdicionarChamadoComponent
      },
      {
        path: 'detalhe/:id',
        component: DetalheComponent
      },
      {
        path: 'editar-chamados/:id',
        component: EditarChamadoComponent
      },
      {
        path: 'perfil',
        component: PerfilComponent
      },
      {
        path: 'notificacoes',
        component: NotificacoesComponent
      },
      {
        path: 'perfil-aluno/:id',
        component: PerfilAlunoComponent
      },
      {
        path: 'perfil-manage',
        component: PerfilManageComponent
      },
      {
        path: 'funcionalidade-manage',
        component: FuncionalidadeManageComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'dashboard-reincidencia',
        component: DashboardReincidenciaComponent
      },
      {
        path: 'dashboard-nao-ocorrencia',
        component: DashboardNaoOcorrenciaComponent
      },
      {
        path: 'agendamento-psicologos',
        component: AgendamentoPsicologosComponent
      },
      {
        path: 'cadastrar-psicologo',
        component: AdicionarPsicologoComponent
      },
      {
        path: 'manutencao-psicologo',
        component: ManutencaoPsicologoComponent
      },
      {
        path: 'editar-psicologo/:id',
        component: EditarPsicologoComponent
      },
      {
        path: 'atendimento-psicologo',
        component: AtendimentoPsicologosComponent
      },
      {
        path: 'adicionar-nao-ocorrencia',
        component: AddNaoOcorrenciaComponent
      },
      {
        path: 'visita-psicologo',
        component: VisitaPsicologoComponent
      },
      {
        path: 'editar-visita-psicologo',
        component: VisitaEditarComponent
      },
      {
        path: 'dashboard-visitas-psicologo',
        component: PageDashboardVisitasComponent
      },
      {
        path: 'dashboard-visitas-poc',
        component: PageDashboardPocVisitasComponent
      },
      {
        path: 'dashboard-visitas-psicologo-fiscal',
        component: DashboardVisitasComponent
      },
      {
        path: 'visualizar-atividade-psicologo/:id',
        component: VisualizarAtividadeComponent
      },
      {
        path: 'visualizar-atividade-professor-poc/:id',
        component: VizualizarAtividadePocComponent
      },
      {
        path: 'visualizar-atividade-psicologo-fiscal/:id',
        component: VisualizarAtividadePsicologoFiscalComponent
      },
      {
        path: 'filtro-visitas-professor',
        component: PageFiltroVisitasProfessorComponent
      },
      {
        path: 'gerenciamento-email',
        component:PageGerenciamentoEmailComponent
      },
      {
        path: 'visita-professor-acolhimento',
        component: PageVisitaProfessorAcolhimentoComponent
      },
      {
        path: 'visita-professor-prevencao',
        component: PageVisitaProfessorPrevencaoComponent
      },
      {
        path: 'filtro-visitas-psicologo',
        component: PageFiltroVisitasPsicologoComponent
      },
      {
        path: 'visita-psicologo-acolhimento/:temDeslocamento',
        component: PageVisitaPsicologoacolhimentoComponent
      },
      {
        path: 'visita-psicologo-prevencao/:temDeslocamento',
        component: PageVisitaPsicologoPrevencaoComponent
      },
      {
        path: 'visita-psicologo-outros/:temDeslocamento',
        component: PageVisitaPsicologoOutrosComponent
      },
      {
        path: 'aprovacao-visitas-psicologo/:id',
        component: PageAprocacaoVisitasComponent
      },
      {
        path: 'aprovacao-visitas-professor-poc/:id',
        component: PageAprocacaoPocVisitasComponent
      },
      {
        path: 'aprovacao-atividade-psicologo-fiscal/:id',
        component: AprocacaoVisitaFiscalComponent
      },
      {
        path: 'consolidado',
        component: ConsolidadoComponent
      },
      {
        path: 'avaliacao-visita-fiscal',
        component: AvailacaoVisitaFiscalComponent
      }
    ]
  },
  {
    path: 'gerenciar-login',
    component: GerenciarLoginComponent,
  },
  {
    path:'login',
    component:LoginComponent,
  },
  {
    path:'logout',
    component:LogoutComponent,
  },
  {
    path:'404',
    component:PageNotFoundComponent,
  },
  {
    path:'**',
    redirectTo:''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
